import { useContext } from "react";
import FileUpload from "../../../../commons/FileUpload";
import { CoApplicantDocsContext } from "../../../..";
import useGetUploadDeleteAndViewCoApplicantDocs from "../../../../hooks/useGetUploadDeleteAndViewCoApplicantDocs";
import Loader from "../../../../commons/Loader";

interface Props {
    tabRelatedData: any;
}

export const DOC_KEYS = ["AGE_PROOF", "ID_PROOF"];

const labelMappings: {
    AGE_PROOF: string;
    ID_PROOF: string;
} = {
    AGE_PROOF: "Age Proof",
    ID_PROOF: "ID Proof",
};

const UploadDocuments = (props: Props) => {
    const { tabRelatedData } = props;

    const {
        coApplicantDocs,
        refetchCoApplicantDocs = () => {},
        setLoader = () => {},
        loader,
        setShowActions = () => {},
    } = useContext(CoApplicantDocsContext);

    const {
        uploadCoApplicantDocumentHandler,
    } = useGetUploadDeleteAndViewCoApplicantDocs({
        refetchCoApplicantDocs,
        setLoader,
    });

    return (
        <div className={``}>
            {(DOC_KEYS || []).map((docKey: string, index: number) => {
                const specificCoApplicantDocs =
                    coApplicantDocs?.[
                        tabRelatedData?.applicantId as keyof typeof coApplicantDocs
                    ];

                const docsToBeRendered = (specificCoApplicantDocs || []).filter(
                    (item: any) => {
                        if (item?.documentName === docKey) {
                            return item;
                        }
                    }
                );

                if (loader) {
                    return (
                        <div key={index}>
                            <Loader index={index} />
                        </div>
                    );
                }

                return (
                    <div
                        key={index}
                        className={`${
                            index === DOC_KEYS.length - 1
                                ? ""
                                : "border-b-[0.07rem] pb-2 mb-4"
                        }`}
                    >
                        <FileUpload
                            id={`${docKey}-${tabRelatedData?.applicantId}`}
                            label={`Upload 
                                ${
                                    labelMappings?.[
                                        docKey as keyof typeof labelMappings
                                    ]
                                }
                            `}
                            required={true}
                            multiple={false}
                            disabled={false}
                            setShowActions={setShowActions}
                            docs={docsToBeRendered}
                            extraProps={{
                                key: docKey,
                                id: tabRelatedData?.applicantId,
                            }}
                            uploadFileHandler={uploadCoApplicantDocumentHandler}
                        />
                    </div>
                );
            })}
        </div>
    );
};

export default UploadDocuments;
