import React, {useContext} from "react";
import {Formik, FormikProps} from "formik"
import Button from "../../../components/Button";
import VerifyDetailsHeader from "../../VerifyDetails/Header";
import * as yup from "yup";
import Webcam from "react-webcam";
import {showToast} from "../../../utils/toast";
import {toastTypes} from "../../../utils/reusableConst";
import {aadhaarNoRegex} from "../../../utils/regEx";
import {AadhaarDetailsContext} from "../../../containers/AadharDetails";

const CaptureAadharDetails = () => {
	const { setOpenCamera, openCamera, navigate, scanAadhaarCardData, webcamRef, setActivePanDetailsPage, setAadhaarCardDetails, aadhaarCardDetails, setScanClick, handleTakePhoto, dataUri, setFillManually } = useContext(AadhaarDetailsContext);

	return (
		<>
			{
				openCamera ?
					<div className="relative">
						<div className="font-semibold text-base p-2">
							Capture {openCamera} page
						</div>
						<Webcam
							audio={false}
							height={800}
							screenshotFormat="image/jpeg"
							width={1280}
							videoConstraints={{
								width: 1280,
								height: 800,
								facingMode: "environment"
							}}
							ref={webcamRef}
						/>
						{
							openCamera === "front" ?
							<button
								onClick={async () => {
									const imageSrc: any = webcamRef?.current?.getScreenshot();
									setOpenCamera("back");
									await handleTakePhoto(imageSrc, "front")
								}}
								className="absolute bottom-3 left-2/4 -translate-x-2/4"
							>
								<div className="w-12"><img src={"assets/icons/shutter-icon.png"} alt="shutter-icon" /></div>
							</button>
							: <button
								onClick={async () => {
									const imageSrc: any = webcamRef?.current?.getScreenshot();
									setOpenCamera("");
									await handleTakePhoto(imageSrc, "back")
								}}
								className="absolute bottom-3 left-2/4 -translate-x-2/4"
							>
								<div className="w-12"><img src={"assets/icons/shutter-icon.png"} alt="shutter-icon" /></div>
							</button>
						}
					</div>
					: <div className="p-4 flex flex-col relative w-full">
						<VerifyDetailsHeader title={"Aadhar Details"} backClickHandler={() => navigate("/verify-details")} />
						<span className="text-neutral-3 mt-2 text-left">
							Please add your Aadhar details
						</span>
						<div className="mt-8 mx-auto">
							<img src="/assets/icons/aadhaar-img.svg" alt="right-arrow-icon" />
						</div>
						<div className="text-xl font-semibold mt-3 text-center">
							Aadhar Number
						</div>
						<div className="text-neutral-3 mt-2 text-center">
							Mandatory for Loan Application
						</div>
						<Formik
							enableReinitialize
							initialValues={{aadhaarNo: ""}}
							validationSchema={yup.object({
								aadhaarNo: yup.string().required('Aadhaar No is required').matches(aadhaarNoRegex, "Invalid Aadhaar No")
							})}
							onSubmit={(values: any) => {}}
						>
							{({values, errors}: FormikProps<any>) => (
								<>
								<div className="mt-5 flex flex-col gap-y-4">
									<div
										onClick={() => {
											setScanClick(false);
											setFillManually(true);
											setActivePanDetailsPage(1);
										}}
										className={"flex item-center justify-center text-primary cursor-pointer text-base"}
									>
										<p>Fill Manually</p>
										<img src={"assets/icons/right-orange-arrow-icon.svg"} className="ml-2" alt={"orange-arrow-icon"} />
									</div>
								</div>
								<div className="text-neutral-3 my-5 text-center">
									or scan using camera
								</div>
								<Button text={"Scan"} type="primaryOutline" onClick={() => {
									setScanClick(true)
									setOpenCamera("front")
								}} />
								{
									dataUri && Object.keys(dataUri).map((key: string) =>{
										return <div className={`mx-auto my-4`}><img src={dataUri?.[key]} alt="Captured"/></div>
									})
								}
								<div className={"fixed bottom-0 bg-white w-full gap-x-2 p-3 left-0 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"}>
									<Button text={"Submit"} type="primary" onClick={async() => {
										let response;
										if(!dataUri && Object.keys(errors).length) return;
										if(dataUri) {
											response = await scanAadhaarCardData();
											response = {
												...response,
												address: response?.addressLine1,
												aadhaarNo: response?.aadhaarNo?.substring(response?.aadhaarNo?.length - 4),
												aadhaarLastFourDigits: response?.aadhaarNo?.substring(response?.aadhaarNo?.length - 4)
											}
										}
										if(response){
											setAadhaarCardDetails({...aadhaarCardDetails, ...response});
											setActivePanDetailsPage(1);
										} else {
											showToast("Invalid Aadhaar Card", toastTypes.error)
										}
									}} />
								</div>
								</>
								)}
						</Formik>
					</div>
			}
		</>
	);
};

export default CaptureAadharDetails;
