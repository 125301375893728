import {toast} from "react-toastify";
import {toastTypes} from "./reusableConst";

export const showToast = (message: string, type: string) => {
	toast[type as keyof typeof toastTypes](message, {
		position: "top-right",
		autoClose: 2500,
		hideProgressBar: true,
		closeOnClick: true,
		draggable: true,
	});
}
