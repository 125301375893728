import React from "react";
import { useNavigate } from "react-router";

const Header = () => {
    const navigate = useNavigate();

    return (
        <div className="flex flex-col items-center w-full">
            <div className="flex justify-between items-center w-full">
                <div className="flex items-center gap-x-2">
                    <div onClick={() => navigate("/verify-details")}>
                        <img
                            src="/assets/icons/back-arrow-icon.svg"
                            alt="back-arrow-icon"
                        />
                    </div>
                    <div className="text-lg font-semibold">
                        Co-Applicant Documents
                    </div>
                </div>
                <div>
                    <img
                        src="/assets/icons/enquiry-icon.svg"
                        alt="enquiry-icon"
                    />
                </div>
            </div>
        </div>
    );
};

export default Header;
