export const formatNumber = (value: number) => {
	if(value) {
		if (value >= 10000000) {
			return `${Math.floor(value / 10000000)} crore`;
		} else if (value >= 100000) {
			return `${Math.floor(value / 100000)} lakh`;
		} else if (value >= 1000) {
			return `${Math.floor(value / 1000)} thousand`;
		} else {
			return value.toString();
		}
	}
}