import React, {Dispatch, SetStateAction, useContext, useState} from "react";
import { Formik } from "formik"
import Button from "../../../components/Button";
import VerifyDetailsHeader from "../../VerifyDetails/Header";
import useGetPanDetails from "../../../containers/PanDetails/useGetPanDetails";
import Input from "../../../components/CustomInput";
import * as yup from "yup";
import Webcam from "react-webcam";
import {toast} from "react-toastify";
import {getPanCardDetails} from "../../../apis/coApplicant";
import {panNoRegex} from "../../../utils/regEx";
import {showToast} from "../../../utils/toast";
import {toastTypes} from "../../../utils/reusableConst";
import {PanDetailsContext} from "../../../containers/PanDetails";

const CapturePanDetails = () => {
	const { setOpenCamera, openCamera, dataUri, handleCapture, navigate, scanPanCardData, webcamRef, setActivePanDetailsPage, setPanCardDetails, setScanClick } = useContext(PanDetailsContext);

	return (
		<>
			{
				openCamera ?
					<div className="relative">
						<Webcam
							audio={false}
							height={800}
							screenshotFormat="image/jpeg"
							width={1280}
							videoConstraints={{
								width: 1280,
								height: 800,
								facingMode: "environment"
							}}
							ref={webcamRef}
						 />
						<button
							onClick={async () => {
								const imageSrc = webcamRef?.current?.getScreenshot();
								await handleCapture(imageSrc)
							}}
							className="absolute bottom-3 left-2/4 -translate-x-2/4"
						>
							<div className="w-12"><img src={"assets/icons/shutter-icon.png"} alt="shutter-icon" /></div>
						</button>
					</div>
					: <div className="p-4 flex flex-col relative h-[100vh-100px] overflow-y-auto w-full">
						<VerifyDetailsHeader title={"PAN Details"} backClickHandler={() => navigate("/verify-details")} />
						<span className="text-neutral-3 mt-2 text-left">
							Please Add your Pan card or enter the details for easy loan approvals.
						</span>
						<div className="mt-8 mx-auto">
							<img src="/assets/icons/pan-img.svg" alt="right-arrow-icon" />
						</div>
						<div className="text-xl font-semibold mt-3 text-center">
							Your PAN
						</div>
						<div className="text-neutral-3 mt-2 text-center">
							Mandatory for Loan Application
						</div>
						<Formik
							enableReinitialize
							initialValues={{panNo: ""}}
							validationSchema={yup.object({
								panNo: yup.string().required('Pan No is required').matches(panNoRegex, "Invalid Pan No")
							})
							}
							onSubmit={(values: any) => {}}
						>
							{({values, errors}: any) => (
								<>
									<div className="mt-5 flex flex-col gap-y-4">
										{/*<Input name={"panNo"} label={""} labelClassName={"uppercase"} onChange={() => setScanClick(false)} />*/}
										<Input name={"panNo"} label={""} labelClassName={"uppercase"} />
									</div>
									<div className="text-neutral-3 mt-2 mb-10 text-center">
										or scan using camera
									</div>
									<Button text={"Scan"} type="primaryOutline" onClick={() => {
										// setScanClick(true)
										setOpenCamera(true)
									}} />
									{dataUri && (
										<div className={`mx-auto my-4`}><img src={dataUri} alt="Captured" /></div>
									)}
									<div className={"fixed bottom-0 bg-white w-full gap-x-2 p-3 left-0 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"}>
										<Button text={"Submit"} type="primary" onClick={async() => {
											let response;
											if(!dataUri && Object.keys(errors).length) return;

											if(dataUri) {
												response = await scanPanCardData();
											} else {
												response = await getPanCardDetails(values.panNo);
											}
											if(response){
												setActivePanDetailsPage(1);
												setPanCardDetails(response);
											} else {
												showToast("Invalid Pan Card", toastTypes.error)
											}
										}} />
									</div>
								</>
							)}
						</Formik>
					</div>
			}
		</>
	);
};

export default CapturePanDetails;
