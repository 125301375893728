import React, { useRef } from "react";
import useOutsideClick from "../../../../hooks/useOutsideClick";
import Actions from "./Actions";

interface Props {
    showActions: any;
    setShowActions: React.Dispatch<React.SetStateAction<any>>;
    deleteDocModal: any;
    setDeleteDocModal: React.Dispatch<any>;
    viewDocumentHandler: ((payload: any) => Promise<void>) | (() => void);
}

const DocumentActions = (props: Props) => {
    const {
        setShowActions = () => {},
        showActions,
        viewDocumentHandler = () => {},
        setDeleteDocModal = () => {},
        deleteDocModal,
    } = props;

    const ref = useRef(null);

    useOutsideClick(ref, () => setShowActions({ ...showActions, show: false }));

    return (
        <div
            className={`fixed bottom-[0%] left-0 w-full h-full transition-all duration-[0.5s] ${
                showActions?.show
                    ? `bg-[rgba(0,0,0,0.6)] z-[99999]`
                    : `z-[-9999]`
            }  bottom `}
        >
            <div className={`relative w-full`}>
                <div
                    className={`flex justify-center items-center h-[2.8rem] w-[2.8rem] mx-auto text-center rounded-[100%] bg-[#FFF] duration-[0.5s] transition-all ease-in-out cursor-pointer ${
                        showActions?.show
                            ? `translate-y-[60vh] max-h-fit ease-in-out border-t-[0.1rem]`
                            : `max-h-fit translate-y-[-100%] bottom-[80%] ease-in-out`
                    }`}
                >
                    <img
                        src="/assets/icons/cross-icon.svg"
                        height={30}
                        width={30}
                    />
                </div>
                <div
                    ref={ref}
                    className={`lg:w-[50%] md:w-[100%] h-[100rem] mx-auto p-10 bg-white shadow rounded-t-[2rem] duration-[0.5s] transition-all ease-in-out ${
                        showActions?.show
                            ? `translate-y-[60vh] max-h-fit pt-2 mt-4 ease-in-out border-t-[0.1rem]`
                            : `max-h-fit translate-y-[100%] bottom-[80%] ease-in-out`
                    }`}
                >
                    <Actions
                        showActions={showActions}
                        setShowActions={setShowActions}
                        viewDocumentHandler={viewDocumentHandler}
                        setDeleteDocModal={setDeleteDocModal}
                        deleteDocModal={deleteDocModal}
                    />
                </div>
            </div>
        </div>
    );
};

export default DocumentActions;
