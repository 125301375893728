import React, { createContext } from "react";
import MainContainer from "../MainContainer";
import useGetAllCoApplicants from "./hooks/useGetAllCoApplicants";
import Header from "./commons/Header";
import CoApplicants from "./components/CoApplicants";
import DeleteDocModal from "./commons/DeleteDocModal";
import DocumentActions from "./commons/DocumentActions";

export const CoApplicantDocsContext = createContext<any>("");

const CoApplicantDocuments = () => {
    const {
        coApplicantsData,
        coApplicantDocs,
        refetchCoApplicantDocs = () => {},
        setLoader = () => {},
        loader,
        deleteDocModal,
        setDeleteDocModal = () => {},
        showActions,
        setShowActions = () => {},
        viewDocumentHandler = () => {},
        deleteDocumentHandler
    } = useGetAllCoApplicants();

    return (
        <CoApplicantDocsContext.Provider
            value={{
                coApplicantsData,
                coApplicantDocs,
                refetchCoApplicantDocs,
                setLoader,
                loader,
                deleteDocModal,
                setDeleteDocModal,
                showActions,
                setShowActions,
            }}
        >
            <MainContainer>
                <div className="p-4 flex flex-col relative h-[calc(100vh-150px)] overflow-y-auto w-full">
                    <Header />
                    <CoApplicants />
                </div>
                {deleteDocModal && (
                    <DeleteDocModal
                        setDeleteDocModal={setDeleteDocModal}
                        deleteDocModal={deleteDocModal}
                        showActions={showActions}
                        setShowActions={setShowActions}
                        deleteDocumentHandler={deleteDocumentHandler}
                    />
                )}
                <DocumentActions
                    showActions={showActions}
                    setShowActions={setShowActions}
                    setDeleteDocModal={setDeleteDocModal}
                    deleteDocModal={deleteDocModal}
                    viewDocumentHandler={viewDocumentHandler}
                />
            </MainContainer>
        </CoApplicantDocsContext.Provider>
    );
};

export default CoApplicantDocuments;
