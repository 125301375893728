import { useContext } from "react";
import { Formik, FormikProps } from "formik";
import Input from "../../../components/CustomInput";
import VerifyDetailsHeader from "../../VerifyDetails/Header";
import Button from "../../../components/Button";
import * as yup from "yup";
import CustomDropdown from "../../../components/Dropdown";
import { AadhaarDetailsContext } from "../../../containers/AadharDetails";

const VerifyAadharDetails = () => {
    const {
        setActivePanDetailsPage,
        aadhaarCardDetails,
        confirmAadharCardDetails,
        genderOptions,
        fillManually,
        maritalStatusOptions,
    } = useContext(AadhaarDetailsContext);

    return (
        <>
            <div className="p-4 flex flex-col relative h-[calc(100vh-150px)] overflow-y-auto w-full">
                <VerifyDetailsHeader
                    title={"Confirm Aadhar Details"}
                    backClickHandler={() => setActivePanDetailsPage(0)}
                />
                <Formik
                    enableReinitialize
                    initialValues={aadhaarCardDetails || {}}
                    validationSchema={yup.object({
                        aadhaarNo: yup
                            .string()
                            .required("Aadhar no is required")
                            .typeError("Only numbers are permitted")
                            .matches(/^[0-9]{4}$/, "Invalid Aadhar No"),
                        name: yup.string().required("Name is required"),
                        fatherName: yup.string().optional(),
                        dateOfBirth: yup.string().optional(),
                        gender: yup.string().required("Gender is required"),
                        address: yup.string().required("Address is required"),
                        city: yup.string().optional(),
                        state: yup.string().optional(),
                        pin: yup
                            .string()
                            .typeError("Only numbers are permitted")
                            .required("PIN is required")
                            .matches(/^[0-9]{6}$/, "Invalid PIN Code"),
                        maritalStatus: yup.string().required("Required"),
                        noOfDependants: yup.number().required("Required"),
                        relationshipWithApplicant: yup.string().required('Required')
                    })}
                    onSubmit={async (values: any) => {
                        const payload = {
                            ...values,
                            relationshipWithApplicant: values?.relationshipWithApplicant,
                            maritalStatus: values?.maritalStatus.toUpperCase(),
                            noOfDependants: Number(values?.noOfDependants),
                            aadhaarLastFourDigits: values?.aadhaarNo,
                            gender: values?.gender?.toUpperCase(),
                        };
                        await confirmAadharCardDetails(payload);
                    }}
                >
                    {({
                        values,
                        setFieldValue,
                        handleSubmit,
                        errors,
                        touched,
                    }: FormikProps<any>) => (
                        <>
                            <div className="mt-10 flex flex-col gap-y-4">
                                <Input
                                    required
                                    name={"aadhaarNo"}
                                    label={"Aadhaar number (Last 4 digits)"}
                                    labelClassName={"uppercase"}
                                    maxLength={4}
                                    inputClassName={"text-neutral-4"}
                                    disabled={!fillManually}
                                />
                                <Input
                                    name={"name"}
                                    maxLength={50}
                                    required
                                    label={"Full Name as on Aadhaar"}
                                    labelClassName={"uppercase"}
                                    inputClassName={"text-neutral-4"}
                                    disabled={aadhaarCardDetails?.name}
                                />
                                <Input
                                    name={"fatherName"}
                                    maxLength={50}
                                    label={"Father's Name"}
                                    inputClassName={"text-neutral-4"}
                                    labelClassName={"uppercase"}
                                    disabled={aadhaarCardDetails?.fatherName}
                                />
                                <Input
                                    name={"dateOfBirth"}
                                    maxDate={new Date()
                                        .toISOString()
                                        .split("T")[0]
                                        .toString()}
                                    type={"date"}
                                    label={"Date of Birth"}
                                    inputClassName={"text-neutral-4"}
                                    labelClassName={"uppercase"}
                                    disabled={aadhaarCardDetails?.dateOfBirth}
                                />
                                <CustomDropdown
                                    required
                                    label="Gender"
                                    id={`gender`}
                                    dropdownOptions={genderOptions}
                                    name={"gender"}
                                    selected={values.gender}
                                    changeHandler={(e: any) =>
                                        setFieldValue("gender", e.target.value)
                                    }
                                    disabled={aadhaarCardDetails?.gender}
                                    error={touched && (errors.gender as string)}
                                />
                                <Input
                                    name={"noOfDependants"}
                                    maxLength={50}
                                    required
                                    label={"No Of Dependants"}
                                    labelClassName={"uppercase"}
                                    inputClassName={"text-neutral-4"}
                                    disabled={false}
                                />
                                <CustomDropdown
                                    required
                                    label="Marital Status"
                                    id={`maritalStatus`}
                                    dropdownOptions={maritalStatusOptions}
                                    name={"gender"}
                                    selected={values.maritalStatus}
                                    changeHandler={(e: any) =>
                                        setFieldValue(
                                            "maritalStatus",
                                            e.target.value
                                        )
                                    }
                                    disabled={aadhaarCardDetails?.maritalStatus}
                                    error={
                                        touched &&
                                        (errors.maritalStatus as string)
                                    }
                                />
                                <Input
                                    name={"relationshipWithApplicant"}
                                    maxLength={50}
                                    required
                                    label={"Relationship with Applicant"}
                                    labelClassName={"uppercase"}
                                    inputClassName={"text-neutral-4"}
                                    disabled={false}
                                />
                                <Input
                                    name={"address"}
                                    maxLength={100}
                                    required
                                    label={"Address"}
                                    inputClassName={"text-neutral-4"}
                                    labelClassName={"uppercase"}
                                />
                                <Input
                                    name={"city"}
                                    maxLength={50}
                                    label={"City"}
                                    inputClassName={"text-neutral-4"}
                                    labelClassName={"uppercase"}
                                />
                                <Input
                                    name={"state"}
                                    maxLength={50}
                                    label={"State"}
                                    inputClassName={"text-neutral-4"}
                                    labelClassName={"uppercase"}
                                />
                                <Input
                                    name={"pin"}
                                    required
                                    maxLength={6}
                                    label={"PIN Code"}
                                    inputClassName={"text-neutral-4"}
                                    labelClassName={"uppercase"}
                                />
                            </div>
                            <div
                                className={
                                    "fixed bottom-0 bg-white w-full gap-x-2 p-3 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"
                                }
                            >
                                <Button
                                    text={"Confirm"}
                                    isSubmit={true}
                                    type="primary"
                                    onClick={() => handleSubmit()}
                                />
                            </div>
                        </>
                    )}
                </Formik>
            </div>
        </>
    );
};

export default VerifyAadharDetails;
