interface Props {
    required: true | false;
    multiple: true | false;
    id: string;
    disabled: true | false;
    uploadFileHandler: any;
    extraProps: any;
    label: string;
    docs: any;
    setShowActions: any;
}

const FileUpload = (props: Props) => {
    const {
        required,
        multiple,
        id,
        extraProps,
        label,
        docs,
        setShowActions = () => {},
    } = props;

    const checkIfDocsUploaded = docs?.length > 0;

    return (
        <div
            onClick={(e) => {
                if (checkIfDocsUploaded && !multiple) {
                    return null;
                }

                document?.getElementById(id)?.click();
            }}
            className={`cursor-pointer transition-all duration-[0.5s] py-2 rounded-md`}
        >
            <div>
                <input
                    type="file"
                    multiple={multiple}
                    required={required || false}
                    className={"h-0 w-0 absolute"}
                    id={id}
                    disabled={props.disabled}
                    onChange={(e) => {
                        props.uploadFileHandler(e, extraProps);
                    }}
                    onClick={(event) => {
                        //@ts-ignore
                        event.target.value = null;
                    }}
                />
                <div className={`relative flex items-center justify-between`}>
                    <div className={`flex items-center`}>
                        <div className={`relative`}>
                            <img
                                src={
                                    checkIfDocsUploaded
                                        ? "/assets/icons/uploaded-doc.svg"
                                        : "/assets/icons/pan-icon.svg"
                                }
                                height={35}
                                width={35}
                            />
                            {checkIfDocsUploaded && (
                                <div
                                    className={`absolute top-[-0.4rem] right-[-0.4rem]`}
                                >
                                    <img
                                        src="/assets/icons/green-tick.svg"
                                        height={15}
                                        width={15}
                                    />
                                </div>
                            )}
                        </div>
                        <div className={`ml-4`}>
                            {checkIfDocsUploaded
                                ? docs?.[0]?.clientFileName
                                : label}
                        </div>
                    </div>
                    <div
                        onClick={
                            checkIfDocsUploaded
                                ? () =>
                                      setShowActions({
                                          docs,
                                          id: extraProps?.id,
                                          show: true,
                                      })
                                : () => {}
                        }
                        className={`min-w-[35px]`}
                    >
                        <img
                            src={
                                checkIfDocsUploaded
                                    ? `/assets/icons/hamburger-horizontal.svg`
                                    : `/assets/icons/add-doc.svg`
                            }
                            height={35}
                            width={35}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FileUpload;
