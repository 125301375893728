import React, {Dispatch, SetStateAction, useState} from "react";
import Button from "../../../components/Button";
import VerifyDetailsHeader from "../../VerifyDetails/Header";
import useGetLivenessCheck from "../../../containers/LivenessCheck/useGetLivenessCheck";
import Webcam from "react-webcam";
import {showToast} from "../../../utils/toast";
import {toastTypes} from "../../../utils/reusableConst";

const CapturePhoto = ({setUserDetails}: {setUserDetails: SetStateAction<Dispatch<any>>}) => {
	const { navigate, setOpenCamera, openCamera, handleTakePhoto, webcamRef, confirmLivenessDetails } = useGetLivenessCheck();

	const base64ToBinary = (base64: string) => {
		const binaryString = window.atob(base64.split(',')[1]);
		const length = binaryString.length;
		const bytes = new Uint8Array(length);

		for (let i = 0; i < length; i++) {
			bytes[i] = binaryString.charCodeAt(i);
		}
		return bytes;
	};

	return (
		openCamera ?
			<div className="relative">
				<Webcam
					audio={false}
					height={800}
					screenshotFormat="image/jpeg"
					width={1280}
					videoConstraints={{
						width: 1280,
						height: 800,
						facingMode: "user"
					}}
					ref={webcamRef}
				/>
				<button
					onClick={async () => {
						const imageSrc = webcamRef?.current?.getScreenshot();
						// const binaryData = base64ToBinary(imageSrc) || "";
						// let formData = new FormData()
						// const blob = new Blob([binaryData]);
						// formData.append("file", blob)
						// const response: any = await handleTakePhoto(formData);
						// if(response?.isLive && !response?.retry) {
						// 	setUserDetails(response);
						// 	setOpenCamera(false);
						// 	formData.append("isAlive", response?.isLive)
						// 	formData.append("alivenessConfidence", response?.confidence)
						// 	await confirmLivenessDetails(formData);
						// }
						const response: any = await handleTakePhoto(imageSrc);
						if(response?.isLive && response.confidence > 75) {
							setUserDetails(response);
							setOpenCamera(false);
							await confirmLivenessDetails({...response, dataUri: imageSrc.split(',')[1]})
						}
						else {
							showToast("Please center face in camera", toastTypes.error)
						}
					}}
					className="absolute bottom-3 left-2/4 -translate-x-2/4"
				>
					<div className="w-12"><img src={"assets/icons/shutter-icon.png"} alt="shutter-icon" /></div>
				</button>
			</div>
			: <>
			<div className="p-4 flex flex-col relative h-[100vh-100px] overflow-y-auto w-full">
				<VerifyDetailsHeader title={"Take a Selfie"} backClickHandler={() => navigate("/verify-details")} />
				<span className="text-neutral-3 mt-10 text-center">
					For a selfie to get approved, please note
				</span>
				<div className="mt-5 flex gap-x-2 mx-auto">
					<div className="w-[30%]">
						<img src="/assets/icons/blur-photo-thumb.png" className="w-full" alt="blur-photo-img" />
						<div className="mt-2 max-w-28 text-center text-xs">Picture is not Blurred</div>
					</div>
					<div className="w-[30%]">
						<img src="/assets/icons/invalid-photo-thumb.svg" className="w-full" alt="invalid-photo-thumb" />
						<div className="mt-2 max-w-28 text-center text-xs">Remove Glasses, masks, cap etc.</div>
					</div>
					<div className="w-[30%]">
						<img src="/assets/icons/correct-photo-thumb.svg" className="w-full" alt="correct-photo-thumb" />
						<div className="mt-2 max-w-28 text-center text-success font-medium text-xs">Clear with Good background light</div>
					</div>
				</div>
				{/*{dataUri && (*/}
				{/*	<div className={`w-[290px] h-[170px] mx-auto my-4`}><img src={dataUri} alt="Captured" /></div>*/}
				{/*)}*/}
				<div className={"fixed bottom-0 bg-white w-full gap-x-2 p-3 left-0 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"}>
					<Button text={"Click Photo"} type="primary" onClick={() => setOpenCamera(true)} />
				</div>
			</div>
		</>
	);
};

export default CapturePhoto;
