import React, { useRef } from "react";
import useOutsideClick from "../../../../hooks/useOutsideClick";

interface Props {
    setDeleteDocModal: React.Dispatch<any>;
    deleteDocModal: any;
    deleteDocumentHandler: (
        payload: any,
        loanApplicantId: any
    ) => Promise<void>;
    showActions: any;
    setShowActions: React.Dispatch<any>;
}

const DeleteDocModal = (props: Props) => {
    const {
        setDeleteDocModal = () => {},
        deleteDocModal,
        deleteDocumentHandler = () => {},
        showActions,
        setShowActions = () => {},
    } = props;
    const ref = useRef(null);

    useOutsideClick(ref, () => setDeleteDocModal(false));

    return (
        <div className="fixed top-0 left-0 w-full h-full bg-[rgba(0,0,0,0.6)] z-[99999] center">
            <div ref={ref} className={`relative top-[40%] w-full`}>
                <div className="w-fit mx-auto relative bg-white shadow rounded-2xl">
                    <div className="flex flex-col justify-center items-center py-1 sm:py-2 px-10 border-b rounded-2xl bg-slate-200 bg-[#ECEDF1]">
                        <div className={`text-[1.2rem] leading-[1.6rem] text-center font-[500]`}>
                            Are you sure you want to <br />
                            Delete the document?
                        </div>
                        <div
                            className={`flex items-center text-[#E26200] font-[600] pt-4 pb-2`}
                        >
                            <div
                                onClick={() => {
                                    setDeleteDocModal(!deleteDocModal);
                                    setShowActions({
                                        ...showActions,
                                        show: true,
                                    });
                                }}
                                className={`cursor-pointer`}
                            >
                                No
                            </div>
                            <div
                                onClick={() =>
                                    deleteDocumentHandler(
                                        showActions?.docs?.[0],
                                        showActions?.id
                                    )
                                }
                                className={`cursor-pointer ml-8 text-[#fff] bg-[#E26200] px-2 py-1 rounded-[0.7rem]`}
                            >
                                Yes, Delete
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeleteDocModal;
