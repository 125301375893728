import { useContext, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {
    getScanAadhaarCardDetails,
    saveAadharCardDetails,
} from "../../apis/coApplicant";
import { showToast } from "../../utils/toast";
import { toastTypes } from "../../utils/reusableConst";
import { MainContext } from "../../routes";

export enum MARITAL_STATUS_TYPES {
    SINGLE = "SINGLE",
    MARRIED = "MARRIED",
    WIDOWED = "WIDOWED",
    DIVORCED = "DIVORCED",
}

const useGetAadharDetails = () => {
    const [openCamera, setOpenCamera] = useState("");
    const [activePanDetailsPage, setActivePanDetailsPage] = useState(0);
    const [dataUri, setDataUri] = useState<any>(null);
    const [scanClick, setScanClick] = useState<boolean>(false);
    const [fillManually, setFillManually] = useState<boolean>(false);
    const [aadhaarCardDetails, setAadhaarCardDetails] = useState<any>({
        aadhaarNo: "",
        name: "",
        fatherName: "",
        dateOfBirth: "",
        gender: "",
        address: "",
        city: "",
        state: "",
        pin: "",
        maritalStatus: "",
        noOfDependants: "",
        relationshipWithApplicant: "",
    });
    const webcamRef = useRef<any>(null);
    const navigate = useNavigate();
    const { coApplicantDetails } = useContext(MainContext);
    const genderOptions = [
        { label: "Male", value: "male" },
        { label: "Female", value: "female" },
    ];
    const maritalStatusOptions = [
        {
            label: MARITAL_STATUS_TYPES.SINGLE,
            value: MARITAL_STATUS_TYPES.SINGLE,
        },
        {
            label: MARITAL_STATUS_TYPES.MARRIED,
            value: MARITAL_STATUS_TYPES.MARRIED,
        },
        {
            label: MARITAL_STATUS_TYPES.DIVORCED,
            value: MARITAL_STATUS_TYPES.DIVORCED,
        },
        {
            label: MARITAL_STATUS_TYPES.WIDOWED,
            value: MARITAL_STATUS_TYPES.WIDOWED,
        },
    ];
    const handleTakePhoto = (dataUriBase64: string, type: string) => {
        setDataUri({ ...dataUri, [`${type}ScanBase64`]: dataUriBase64 });
    };

    const scanAadhaarCardData = async () => {
        let body = {};
        dataUri &&
            Object.keys(dataUri).map((key: string) => {
                body = {
                    ...body,
                    [key]: dataUri?.[key].split(",")[1],
                };
            });
        return await getScanAadhaarCardDetails(body);
    };

    const confirmAadharCardDetails = async (payload: any) => {
        const response: any = await saveAadharCardDetails(payload);
        if (response?.data?.success) {
            if (!coApplicantDetails?.alivenessDetails) {
                navigate("/liveness-check");
            } else {
                navigate("/verify-details");
            }
            showToast(
                "Aadhaar Card details save successfully",
                toastTypes.success
            );
        } else {
            showToast(
                response?.response?.data?.message?.[0]?.message ||
                    "Failed to save Aadhaar Card details",
                toastTypes.error
            );
        }
    };

    return {
        setOpenCamera,
        openCamera,
        navigate,
        dataUri,
        handleTakePhoto,
        activePanDetailsPage,
        setActivePanDetailsPage,
        scanAadhaarCardData,
        aadhaarCardDetails,
        setAadhaarCardDetails,
        webcamRef,
        confirmAadharCardDetails,
        genderOptions,
        setScanClick,
        scanClick,
        setFillManually,
        fillManually,
        maritalStatusOptions,
    };
};

export default useGetAadharDetails;
