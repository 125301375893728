import {useRef, useState} from "react";
import {useNavigate} from "react-router";
import {
	getUserDetails,
	saveLivenessDetails
} from "../../apis/coApplicant";
import {toast} from "react-toastify";
import {showToast} from "../../utils/toast";
import {toastTypes} from "../../utils/reusableConst";

const useGetLivenessCheck = () => {
	const [openCamera, setOpenCamera ] = useState(false);
	const [activeLivenessPage, setActiveLivenessPage] = useState(0);
	const [userDetails, setUserDetails] = useState(null);
	const webcamRef = useRef<any>(null);
	const navigate = useNavigate();

	// const handleTakePhoto = async (dataUri: any) => {
	// 	return await getUserDetails(dataUri);
	// };

	const handleTakePhoto = async (dataUri: string) => {
		return await getUserDetails({faceScanBase64: dataUri.split(',')[1] });
	};

	const confirmLivenessDetails = async (payload: any) => {
		// const response = await saveLivenessDetails(payload);
		const body = {
			isAlive: payload?.isLive,
			alivenessConfidence: payload?.confidence,
			faceScanBase64: payload?.dataUri
		}
		const response = await saveLivenessDetails(body);
		if(response?.success) {
			navigate("/verify-details");
			showToast("Photo submitted successfully", toastTypes.success)
		} else {
			showToast("Failed to submit photo", toastTypes.error)
		}
	}

	return {
		setOpenCamera,
		openCamera,
		navigate,
		handleTakePhoto,
		activeLivenessPage,
		setActiveLivenessPage,
		userDetails,
		setUserDetails,
		webcamRef,
		confirmLivenessDetails
	}
};

export default useGetLivenessCheck;