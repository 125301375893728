import React, { useContext, useState } from "react";
import { CoApplicantData } from "../../types";
import CoApplicantCard from "./CoApplicantCard";
import { CoApplicantDocsContext } from "../..";

const CoApplicants = () => {
    const { coApplicantsData, loader } = useContext(CoApplicantDocsContext);

    const [openTab, setOpenTab] = useState<{
        id: string;
        data: any;
    }>();

    const handleOpenTab = (coApplicant: CoApplicantData) => {
        if (!openTab?.id || openTab?.id !== String(coApplicant.applicantId)) {
            setOpenTab({
                id: String(coApplicant.applicantId),
                data: coApplicant,
            });
        } else {
            setOpenTab({
                id: "",
                data: {},
            });
        }
    };

    return (
        <div>
            {(coApplicantsData || []).map(
                (coApplicant: CoApplicantData, index: number) => {
                    if (index === 0) {
                        return null;
                    }

                    return (
                        <div key={index} className={`mt-4`}>
                            <CoApplicantCard
                                coApplicant={coApplicant}
                                openTab={openTab}
                                handleOpenTab={handleOpenTab}
                            />
                        </div>
                    );
                }
            )}
        </div>
    );
};

export default CoApplicants;
