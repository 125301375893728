import React  from "react";

interface IProps {
	title: string,
	backClickHandler: () => void
}

const VerifyDetailsHeader = ({title, backClickHandler}: IProps) => {
	return (
		<>
			<div className="flex flex-col items-center w-full">
				<div className="flex justify-between items-center w-full">
					<div className="flex items-center gap-x-2">
						<div onClick={backClickHandler}>
							<img src="/assets/icons/back-arrow-icon.svg" alt="back-arrow-icon" />
						</div>
						<div className="text-lg font-semibold">
							{title}
						</div>
					</div>
					<div>
						<img src="/assets/icons/enquiry-icon.svg" alt="enquiry-icon" />
					</div>
				</div>
			</div>
		</>
	);
};

export default VerifyDetailsHeader;
