import React, { ReactNode } from 'react'
import { useField } from 'formik'

interface Props {
  id?: string
  name: string,
  label?: ReactNode,
  icon?: ReactNode,
  submitButton?: ReactNode,
  type?: 'text' | 'textarea' | 'email' | 'password' | 'date',
  placeholder?: string
  showCheckLogo?: boolean,
  disabled?: boolean,
  helperText?: string,
  onChange?: (e: string) => void,
  autoCapitalize?: boolean
  required?:boolean,
  maxLength?:number | string,
  labelClassName?: string,
  inputClassName?: string,
  maxDate?: string
}

function Input(props: Props) {
  const {
    icon,
    label,
    submitButton,
    name,
    type = 'text',
    placeholder = '',
    showCheckLogo = false,
    helperText = '',
    disabled = false,
    onChange,
    autoCapitalize = false,
    required= false,
    maxLength=100,
    id,
    labelClassName,
    inputClassName,
    maxDate
  } = props

  const [field, meta, helpers] = useField(name);

  return (
    <div>
      <div className={'flex items-center'}>
        <label className={`text-xs text-neutral-4 ${labelClassName}`}>{label}</label>
        <label className='text-red-600'>{required ? '*' : null} </label>
        {/*<div className={`ml-[70px] ${helperText.length ? 'display' : 'hidden'}`}>*/}
        {/*  <img src={i_logo} alt={'i_logo'} width={16} height={16} />*/}
        {/*</div>*/}
      </div>
      <div className={'relative mt-2 flex justify-end'}>
        <input
          id={id}
          {...field}
          type={type}
          className={`w-full p-3 rounded-xl border border-neutral-6 ${showCheckLogo ? 'pr-[40px]' : ''}
          ${meta.touched && meta.error ? 'border-red-600' : 'border-[#D9D9D9]'}
          ${autoCapitalize ? "uppercase" : ""}
          ${inputClassName}`}
          placeholder={placeholder}
          name={name}
          disabled={disabled}
          maxLength={Number(maxLength)}
          onChange={(event) => {
            if (onChange) {
              onChange(event.target.value)
            }
            helpers.setValue(event.target.value)
          }}
          max={maxDate}
        />
        <div className={`absolute top-3 right-3 leading-none cursor-pointer`}>
          {icon}
        </div>
        {/*{showCheckLogo && <div className={`absolute top-[15px] right-[10px] leading-none`}>*/}
        {/*  <img src={check} alt={'checkLogo'} width={22} height={22} />*/}
        {/*</div>}*/}
        {submitButton && <div className={`absolute top-0 right-0 h-[50px]`}>
          {submitButton}
        </div>}
      </div>

      {meta.touched && meta.error ? (
        <div className='text-red-600 text-xs pl-1 pt-0.5'>{meta.error}</div>
      ) : null}
    </div>
  )
}

export default Input;
