import {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router";
import {getScanPanCardDetails, savePanCardDetails} from "../../apis/coApplicant";
import {showToast} from "../../utils/toast";
import {toastTypes} from "../../utils/reusableConst";
import {MainContext} from "../../routes";

const useGetPanDetails = () => {
	const [openCamera, setOpenCamera] = useState(false);
	const [activePanDetailsPage, setActivePanDetailsPage] = useState(0)
	const [dataUri, setDataUri] = useState("");
	const [panCardDetails, setPanCardDetails] = useState({});
	const [scanClick, setScanClick] = useState<boolean>(false);

	const webcamRef = useRef<any>(null);
	const navigate = useNavigate();
	const {coApplicantDetails} = useContext(MainContext);


	const handleCapture = (dataUri: string) => {
		setDataUri(dataUri);
		setOpenCamera(false)
	};

	const confirmPanCardDetails = async (payload: any) => {
		const response: any = await savePanCardDetails(payload);
		console.log("response", response)
		if(response?.data?.success) {
			if(!coApplicantDetails?.aadhaarDetails) {
				navigate("/aadhaar-details")
			} else if(!coApplicantDetails?.alivenessDetails) {
				navigate("/liveness-check")
			} else {
				navigate("/verify-details")
			}
			showToast( "Pan Card details save successfully", toastTypes.success)
		} else {
			showToast(response?.response?.data?.message?.[0]?.message || "Failed to save pan card details", toastTypes.error)
		}
	}

	const scanPanCardData = async() => {
		return await getScanPanCardDetails({frontScanBase64: dataUri.split(',')[1] });
	}

	return {
		setOpenCamera,
		openCamera,
		navigate,
		dataUri,
		handleCapture,
		activePanDetailsPage,
		setActivePanDetailsPage,
		scanPanCardData,
		panCardDetails,
		setPanCardDetails,
		webcamRef,
		confirmPanCardDetails,
		scanClick,
		setScanClick
	}
};

export default useGetPanDetails;