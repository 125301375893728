export const isFile = (input: File | Object) => {
    if ("File" in window && input instanceof File) return true;
    else return false;
};

export const convertJsonToFormData = (
    obj: any,
    formData: FormData,
    formedKey: string
) => {
    const checkIfDotApplies = (formedKey: string, key: string) => {
        return formedKey.length == 0 ? `${key}` : `.${key}`;
    };
    if (!obj) return;
    for (let key of Object.keys(obj)) {
        if (key === "form60" && isFile(obj?.[key]) === false) continue;

        if (obj?.[key] === undefined) continue;

        if (typeof obj?.[key] !== "object") {
            formData.append(
                formedKey + `${checkIfDotApplies(formedKey, key)}`,
                obj[key]
            );
        } else if (Array.isArray(obj?.[key])) {
            for (let [i, item] of obj[key].entries()) {
                convertJsonToFormData(
                    item,
                    formData,
                    formedKey +
                        `${checkIfDotApplies(formedKey, key + `[${i}]`)}`
                );
            }
        } else {
            convertJsonToFormData(
                obj?.[key],
                formData,
                formedKey + `${checkIfDotApplies(formedKey, key)}`
            );
        }
    }
};
