import React, {Dispatch, SetStateAction, useContext, useEffect, useState} from "react";
import MainContainer from "../MainContainer";
import Button from "../../components/Button";
import {useLocation, useNavigate} from "react-router";
import {setCookie} from "../../utils/cookies";
import {formatNumber} from "../../utils/number";
import {MainContext} from "../../routes";

const Home = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const queryParams = new URLSearchParams(location.search);
	const paramValue = queryParams.get('token');
	const {coApplicantDetails, setCoApplicantDetails} = useContext(MainContext);

	if(paramValue) {
		setCookie(
			`${process.env.REACT_APP_SESSION_COOKIE_NAME}`,
			paramValue
		);
	}

	return (
		<MainContainer>
			<div className="p-4 flex flex-col items-center relative overflow-y-auto h-[100vh-100px]">
				<div className="text-xl mb-2 ">
					Get Instant Solar Loans with
				</div>
				<div>
					<img src="/assets/icons/aerem-logo.svg" alt="aerem-logo" />
				</div>
				<div className="bg-[#FFFAEA] rounded-xl border-neutral-6 relative mt-14 p-4 pt-8 text-center">
					<img src="/assets/icons/user-icon.svg" alt="aerem-logo" className="absolute -top-5 left-2/4 -translate-x-2/4" />
					<span className="font-medium">{coApplicantDetails?.maainApplicantName?.name}</span> has added you as a co-applicant in  Solar loan with Aerem for your Business
					<div className="font-medium mt-2 text-base">₹ {coApplicantDetails?.loanAmount || 0}</div>
					<div>(₹ {formatNumber(coApplicantDetails?.loanAmount)})</div>
				</div>
				<div className="font-medium mt-10 text-center">
					Verify your details to proceed with the Application
				</div>
				<div className={"fixed bottom-0 bg-white w-full gap-x-2 p-3 left-0 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"}>
					<Button text={"Verify Details"} type="primary" onClick={() => navigate("/verify-details")} />
				</div>
			</div>
		</MainContainer>
	);
};

export default Home;
