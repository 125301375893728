import React from "react";

interface Props {
    viewDocumentHandler: ((payload: any) => Promise<void>) | (() => void)
    showActions: any
    setShowActions: React.Dispatch<any>
    deleteDocModal: any
    setDeleteDocModal: React.Dispatch<any>
}

const Actions = (props: Props) => {
    const { viewDocumentHandler = () => {}, showActions, setShowActions = () => {}, setDeleteDocModal = () => {}, deleteDocModal } = props

    const actionMappings = [
        {
            title: "View Document",
            icon: "/assets/icons/view-icon.svg",
            action: () => {
                viewDocumentHandler(showActions?.docs?.[0])
            }
        },
        {
            title: "Delete Document",
            icon: "/assets/icons/delete-icon.svg",
            action: () => {
                setDeleteDocModal(!deleteDocModal)
                console.log('showActionsshowActions ::', showActions);
                
                setShowActions({ ...showActions, show: false  })
            }
        }
    ]
    return (
        <div className={`mt-5`}>
            {
                (actionMappings || []).map((item: any, index: number) => {
                    return (
                        <div key={index} onClick={() => item?.action()} className={`flex items-center rounded-[1rem] px-3 py-3 mb-3 hover:bg-slate-200 transition-all duration-[0.5s] cursor-pointer`}>
                            <div>
                                <img src={item?.icon} height={50} width={50} /> 
                            </div>
                            <div className={`ml-5 text-[1.1rem]`}>
                                {item?.title}
                            </div>
                        </div>
                    )
                })
            }
        </div>
    );
};

export default Actions;
