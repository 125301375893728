import { createContext } from "react";
import MainContainer from "../MainContainer";
import useGetAadharDetails from "./useGetAadharDetails";
import CaptureAadharDetails from "../../layouts/AddharDetails/CaptureAadharDetails";
import VerifyAadharDetails from "../../layouts/AddharDetails/VerifyAadharDetails";

export const AadhaarDetailsContext = createContext<any>("lll");

const AadharDetails = () => {
    const {
        activePanDetailsPage,
        setActivePanDetailsPage,
        confirmAadharCardDetails,
        setAadhaarCardDetails,
        aadhaarCardDetails,
        genderOptions,
        setScanClick,
        scanClick,
        dataUri,
        handleTakePhoto,
        setOpenCamera,
        openCamera,
        navigate,
        scanAadhaarCardData,
        webcamRef,
        setFillManually,
        fillManually,
        maritalStatusOptions,
    } = useGetAadharDetails();

    return (
        <AadhaarDetailsContext.Provider
            value={{
                setOpenCamera: setOpenCamera,
                openCamera: openCamera,
                navigate: navigate,
                scanAadhaarCardData: scanAadhaarCardData,
                webcamRef: webcamRef,
                handleTakePhoto: handleTakePhoto,
                dataUri: dataUri,
                setScanClick: setScanClick,
                aadhaarCardDetails: aadhaarCardDetails,
                setAadhaarCardDetails: setAadhaarCardDetails,
                setActivePanDetailsPage: setActivePanDetailsPage,
                confirmAadharCardDetails: confirmAadharCardDetails,
                genderOptions: genderOptions,
                scanClick: scanClick,
                setFillManually,
                fillManually,
				maritalStatusOptions: maritalStatusOptions
            }}
        >
            <MainContainer>
                {activePanDetailsPage === 0 && <CaptureAadharDetails />}
                {activePanDetailsPage === 1 && <VerifyAadharDetails />}
            </MainContainer>
        </AadhaarDetailsContext.Provider>
    );
};

export default AadharDetails;
