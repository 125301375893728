import { uploadCoApplicantDocuments } from "../../../apis/coApplicant";
import { toast } from "react-toastify";

const useGetUploadAndViewCoApplicantDocs = ({
    refetchCoApplicantDocs = () => {},
    setLoader = () => {},
}: {
    refetchCoApplicantDocs: any;
    setLoader: any;
}) => {
    const uploadCoApplicantDocumentHandler = async (
        event: any,
        extraProps: any
    ) => {
        try {
            setLoader(true);
            const payload = {
                files: event?.target?.files,
                id: extraProps.id,
                fileType: extraProps.key,
            };

            const response = await uploadCoApplicantDocuments(payload);

            if (response?.data?.success) {
                await refetchCoApplicantDocs(extraProps.id);
                toast.success("Document uploaded successfully!", {
                    toastId: "toastId",
                });
            }

            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    };

    return {
        uploadCoApplicantDocumentHandler,
    };
};

export default useGetUploadAndViewCoApplicantDocs;
