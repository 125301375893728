import React from "react";

export interface DropdownOption {
  label: string;
  value: string | boolean;
  disabled?: boolean;
}

interface CustomDropdownProps {
  id: string | number;
  dropdownOptions: DropdownOption[] | [];
  label: string;
  changeHandler: any;
  required?: boolean;
  name: string;
  selected: string;
  error?: string;
  styles?: any;
  selectStyles?: any;
  blurHandler?: any;
  disabled?: boolean
}

const CustomDropdown = ({
  id,
  dropdownOptions,
  label,
  changeHandler,
  name,
  selected,
  styles,
  error,
  selectStyles,
  blurHandler,
  disabled,
  required
}: CustomDropdownProps) => {

  return (
    <div
      style={styles}
      className={`flex flex-col justify-start items-start w-full`}
    >
      <div className={`w-full text-left`}>
        <div className={'flex items-center'}>
          <label className={`text-xs text-neutral-4 uppercase`}>{label}</label>
          <label className='text-red-600'>{required ? '*' : null} </label>
        </div>
        <select
          id={`select_${id || ""}`}
          className={`p-3 mt-[6px] border border-[#E6E6E6] rounded-[5px] focus: outline-none w-full truncate text-neutral-4`}
          name={name}
          style={selectStyles}
          onChange={changeHandler}
          onBlur={blurHandler}
          disabled={disabled}
        >
          {!selected && <option>Select One</option>}
          {dropdownOptions.map((option: DropdownOption) => (
            <option
              key={`option_${option.value?.toString()?.toLowerCase()}`}
              value={option.value?.toString()?.toLowerCase() as string}
              selected={option.value?.toString()?.toLowerCase() === selected?.toString()?.toLowerCase()}
              disabled={option.disabled}
            >
              {option.label}
            </option>
          ))}
        </select>
      </div>
      {error && (
        <div className="block text-xs text-red-600 mt-[4px]">{error}</div>
      )}
    </div>
  );
};

export default CustomDropdown;
