const ActionCard = ({
    title,
    icon,
    isCompleted,
    onClick,
}: {
    title: string;
    icon: any;
    isCompleted?: boolean;
    onClick?: () => void;
}) => {
    return (
        <div
            className={`flex items-center border p-4 rounded-xl w-full ${
                isCompleted ? "bg-[#EFF8F4]" : " cursor-pointer"
            }`}
            onClick={() => !isCompleted && onClick?.()}
        >
            <div className="flex items-center w-full">
                <div>
                    <img src={icon} />
                </div>
                <div className="flex flex-col ml-3">
                    <span className="">{title}</span>
                    {isCompleted && (
                        <span className="text-success font-semibold">
                            Completed
                        </span>
                    )}
                </div>
            </div>
            <div>
                {!isCompleted && (
                    <img
                        src="/assets/icons/right-arrow-icon.svg"
                        alt="right-arrow-icon"
                    />
                )}
            </div>
        </div>
    );
};

export default ActionCard;
