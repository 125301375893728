import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
    confirmCoApplicantKycDetails,
    getCoApplicantDetails,
} from "../../apis/coApplicant";
import { showToast } from "../../utils/toast";
import { toastTypes } from "../../utils/reusableConst";
import { UploadProofDetails } from "../../types";

const useGetVerifyDetails = ({
    setCoApplicantDetails,
    coApplicantDetails,
}: {
    setCoApplicantDetails: Dispatch<SetStateAction<any>>;
    coApplicantDetails: any;
}) => {
    const [showSuccessPage, setShowSuccessPage] = useState(false);
    const [termsConditionCheck, toggleTermsConditionCheck] = useState(false);
    const [validationError, setValidationError] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        (async () => {
            let response = await getCoApplicantDetails();
            response = {
                ...response?.loanApplicantsdata,
                loanAmount: parseFloat(
                    response?.loanApplicantsdata?.loanAmount
                ).toFixed(2),
                name: response?.loanApplicantsdata.maainApplicantName?.name,
            };
            setCoApplicantDetails(response);
        })();
    }, []);

    const confirmKycDetails = async () => {
        let response = await confirmCoApplicantKycDetails();
        if (response?.success) {
            setShowSuccessPage(true);
            setValidationError(false);
        } else {
            showToast("Failed to submit details", toastTypes.error);
        }
    };

    const uploadProofMappings: UploadProofDetails[] = [
        {
            title: "Upload PAN",
            icon: "/assets/icons/pan-icon.svg",
            isCompleted: coApplicantDetails?.panDetails,
            onClick: () => navigate("/pan-details"),
        },
        {
            title: "Upload Aadhaar",
            icon: "/assets/icons/aadhar-icon.svg",
            isCompleted: coApplicantDetails?.aadhaarDetails,
            onClick: () => navigate("/aadhaar-details"),
        },
        {
            title: "Upload Co-Applicant Documents",
            icon: "/assets/icons/pan-icon.svg",
            isCompleted: false,
            onClick: () => navigate("/co-applicant-documents"),
        },
        {
            title: "Liveness Check",
            icon: "/assets/icons/camera-icon.svg",
            isCompleted: coApplicantDetails?.alivenessDetails,
            onClick: () => navigate("/liveness-check"),
        },
    ];

    return {
        navigate,
        termsConditionCheck,
        toggleTermsConditionCheck,
        setShowSuccessPage,
        showSuccessPage,
        setValidationError,
        validationError,
        confirmKycDetails,
        uploadProofMappings,
    };
};

export default useGetVerifyDetails;
