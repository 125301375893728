export const getBinaryDecodedCookieValue = (asciValue: any) => {
  try {
    const cookie = JSON.parse(atob(asciValue));
    return cookie?.message;
  } catch (error) {
    return false;
  }
};

export const setCookie = (cname: string, cvalue: any) => {
  document.cookie = `${cname}=${cvalue};path=/`;
};

export const getCookie = (cname: string) => {
  let name = cname + "=";
  let decodedCookie = document.cookie;
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const deleteCookie = (cname: string) => {
  document.cookie = cname + "=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/;";
};
