import { useContext } from "react";
import { Formik } from "formik";
import MainContainer from "../MainContainer";
import Button from "../../components/Button";
import VerifyDetailsHeader from "../../layouts/VerifyDetails/Header";
import Checkbox from "../../components/Checkbox";
import useGetVerifyDetails from "./useGetVerifyDetails";
import * as yup from "yup";
import Input from "../../components/CustomInput";
import { MainContext } from "../../routes";
import { UploadProofDetails } from "../../types";
import ActionCard from "../../components/ActionCard";

const VerifyDetails = () => {
    const { coApplicantDetails, setCoApplicantDetails } =
        useContext(MainContext);

    const {
        navigate,
        termsConditionCheck,
        toggleTermsConditionCheck,
        setShowSuccessPage,
        showSuccessPage,
        setValidationError,
        validationError,
        confirmKycDetails,
		uploadProofMappings
    } = useGetVerifyDetails({ setCoApplicantDetails, coApplicantDetails });

    const isAllDetailsSubmitted =
        coApplicantDetails?.panDetails &&
        coApplicantDetails?.aadhaarDetails &&
        coApplicantDetails?.alivenessDetails;

    return (
        <MainContainer>
            {showSuccessPage ? (
                <div className=" mx-auto p-4 mt-5">
                    <div className="max-w-xs mx-auto">
                        <img
                            src="/assets/icons/success-img.svg"
                            className="w-full"
                        />
                    </div>
                    <div className="text-2xl font-semibold mt-3 text-center leading-8 text-neutral-3 max-w-xs mx-auto">
                        Details submitted successfully
                    </div>
                    <div className="leading-5 text-base text-center mt-3 max-w-xs">
                        All the details were submitted successfully, we will
                        notify you when the loan gets approved.
                    </div>
                </div>
            ) : (
                <>
                    <div className="p-4 flex flex-col relative w-full">
                        <div className="h-[calc(100vh-140px)] overflow-y-auto">
                            <VerifyDetailsHeader
                                title={"Verify Details"}
                                backClickHandler={() => navigate("/")}
                            />
                            <span className="text-neutral-3 mt-2 text-left">
                                Please verify & upload your necessary details.
                                All documents are Mandatory for KYC verification
                            </span>
                            <Formik
                                enableReinitialize
                                initialValues={coApplicantDetails}
                                validationSchema={yup.object({
                                    name: yup
                                        .string()
                                        .required("Pan no is required"),
                                })}
                                onSubmit={() => {}}
                            >
                                {() => (
                                    <div className="mt-10 flex flex-col gap-y-4">
                                        <Input
                                            required
                                            name={"aadhaarName"}
                                            label={"Full Name (As on aadhaar)"}
                                            labelClassName={"uppercase"}
                                            inputClassName={"text-neutral-4"}
                                            disabled
                                        />
                                        <Input
                                            required
                                            name={"entityNamae"}
                                            label={"Entity Name"}
                                            labelClassName={"uppercase"}
                                            inputClassName={"text-neutral-4"}
                                            disabled
                                        />
                                        <Input
                                            required
                                            name={"loanAmount"}
                                            label={"Business Loan Amount"}
                                            inputClassName={"text-neutral-4"}
                                            labelClassName={"uppercase"}
                                            disabled
                                        />
                                    </div>
                                )}
                            </Formik>
                            <div className="flex flex-col gap-y-5 mt-5 w-full">
								{
									(uploadProofMappings || []).map((item: UploadProofDetails, index: number) => {
										return (
											<div key={index}>
												<ActionCard {...item} />
											</div>
										)
									})
								}
                            </div>
                            <div className="mt-5 flex items-center">
                                <Checkbox
                                    checked={termsConditionCheck}
                                    changeHandler={() => {
                                        setValidationError(false);
                                        toggleTermsConditionCheck(
                                            !termsConditionCheck
                                        );
                                    }}
                                />
                                <div className="text-neutral-4 ml-2 text-xs">
                                    I agree to being a co-applicant on this loan
                                    and also agree to the Aerem Terms and
                                    Conditions.
                                </div>
                            </div>
                            {validationError && (
                                <div className="text-red-600 mt-1 ml-7 text-xs">
                                    Please accept terms and conditions
                                </div>
                            )}
                        </div>
                        <div
                            className={
                                "fixed bottom-0 bg-white w-full gap-x-2 p-3 left-0 max-w-3xl mx-auto left-1/2 translate-x-[-50%]"
                            }
                        >
                            <Button
                                text={
                                    isAllDetailsSubmitted
                                        ? "Submit Details"
                                        : "Verify Details"
                                }
                                type="primary"
                                onClick={async () => {
                                    if (!termsConditionCheck) {
                                        setValidationError(true);
                                        return;
                                    }
                                    if (isAllDetailsSubmitted) {
                                        await confirmKycDetails();
                                    } else {
                                        if (!coApplicantDetails?.panDetails) {
                                            navigate("/pan-details");
                                        } else if (
                                            !coApplicantDetails?.aadhaarDetails
                                        ) {
                                            navigate("/aadhaar-details");
                                        } else if (
                                            !coApplicantDetails?.alivenessDetails
                                        ) {
                                            navigate("/liveness-check");
                                        }
                                    }
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </MainContainer>
    );
};

export default VerifyDetails;
