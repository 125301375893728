import React from "react";
import MainContainer from "../MainContainer";
import useGetLivenessCheck from "./useGetLivenessCheck";
import CapturePhoto from "../../layouts/LivenessCheck/CapturePhoto";

const LivenessCheck = () => {
	const { activeLivenessPage, setUserDetails } = useGetLivenessCheck();

	return (<MainContainer>
			{
				activeLivenessPage === 0 &&
				<CapturePhoto setUserDetails={setUserDetails} />
			}
		</MainContainer>
	);
};

export default LivenessCheck;
