import React from "react";
import { CoApplicantData } from "../../../types";
import UploadDocuments from "./UploadDocuments";

interface Props {
    coApplicant: CoApplicantData;
    openTab:
        | {
              id: string;
              data: any;
          }
        | undefined;
    handleOpenTab: (coApplicant: CoApplicantData) => void;
}

const CoApplicantCard = (props: Props) => {
    const { coApplicant, handleOpenTab = () => {}, openTab } = props;

    return (
        <div className={`border p-4 rounded-xl`}>
            <div
                className={`flex items-center w-full cursor-pointer`}
                onClick={() => handleOpenTab(coApplicant)}
            >
                <div className="flex items-center w-full">
                    <div>
                        <img src={`/assets/icons/pan-icon.svg`} />
                    </div>
                    <div className="flex flex-col ml-3">
                        <span className="">{coApplicant.applicantName}</span>
                    </div>
                </div>
                <div
                    className={`transition-transform duration-500 ease-in-out ${
                        openTab?.id === String(coApplicant.applicantId)
                            ? "rotate-[180deg]"
                            : ""
                    }`}
                >
                    <img
                        src="/assets/icons/down-arrow-icon.svg"
                        alt="right-arrow-icon"
                    />
                </div>
            </div>
            <div className="overflow-hidden">
                <div
                    className={` duration-[0.5s] transition-all ease-in-out ${
                        openTab?.id === String(coApplicant.applicantId)
                            ? `translate-y-[0%] max-h-fit pt-2 mt-4 ease-in-out border-t-[0.1rem]`
                            : `max-h-0 translate-y-[-100%] ease-in-out`
                    }`}
                >
                    <UploadDocuments tabRelatedData={openTab?.data} />
                </div>
            </div>
        </div>
    );
};

export default CoApplicantCard;
