import React from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { DOC_KEYS } from "../../components/CoApplicants/CoApplicantCard/UploadDocuments";

const Loader = ({ index }: { index: number }) => {
    return (
        <div
            className={`pb-4 ${
                index === DOC_KEYS.length - 1
                    ? ""
                    : "border-b-[0.07rem] pb-2 mb-4"
            }`}
        >
            <div>
                <div className={`flex items-center`}>
                    <div id={`add-skeleton`}>
                        <Skeleton height={30} width={30} />
                    </div>
                    <div id={`label-skeleton`} className={`ml-4`}>
                        <Skeleton height={10} width={100} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Loader;
